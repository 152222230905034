import React, { createContext, useState } from "react"

export const CursorContext = createContext({
  config: {},
  setConfig: () => { }
});

export const CursorProvider = props => {
  const [cursorConfig, setCursorConfig] = useState({
    shape: 'default',
    color: 'var(--darkgrey)',
    rotation: -30,
    translation: '-6px, -6px',
    scale: '1',
    blocked: false,
  });

  const setCursor = (config) => {

    const newCursorConfig = {
      ...cursorConfig,
      ...config,
      rotation: getRotationDeg(cursorConfig.rotation, config.rotation),
    }

    cursorConfig.blocked
      ? !config.blocked && setCursorConfig(newCursorConfig)
      : setCursorConfig(newCursorConfig)
  }

  return <CursorContext.Provider value={{ cursorConfig, setCursor }} {...props} />
}

const getRotationDeg = (oldRotation, newRotation) => {
  if (oldRotation || oldRotation === 0) {
    return oldRotation + getRotationDelta(parseInt(oldRotation), parseInt(newRotation));
  } else return newRotation;
}

const toRadians = (angle) => {
  return angle * (Math.PI / 180);
}

const getRotationDelta = (oldRotation, newRotation) => {

  if (isNaN(newRotation)) return 0
  const oldDir = directionFromRotation(oldRotation)
  const newDir = directionFromRotation(newRotation)

  if (oldDir === newDir) {
    return 0;
  } else if (oldDir === "up" && newDir === "right") {
    return 90;
  } else if (oldDir === "up" && newDir === "down") {
    return 180
  } else if (oldDir === "up" && newDir === "left") {
    return -90
  } else if (oldDir === "up" && newDir === "tilted") {
    return -30
  } else if (oldDir === "tilted" && newDir === "right") {
    return 120
  } else if (oldDir === "tilted" && newDir === "down") {
    return -150
  } else if (oldDir === "tilted" && newDir === "left") {
    return -60
  } else if (oldDir === "tilted" && newDir === "up") {
    return 30
  } else if (oldDir === "right" && newDir === "tilted") {
    return -120
  } else if (oldDir === "right" && newDir === "up") {
    return -90
  } else if (oldDir === "right" && newDir === "down") {
    return 90
  } else if (oldDir === "right" && newDir === "left") {
    return -180
  } else if (oldDir === "down" && newDir === "up") {
    return 180
  } else if (oldDir === "down" && newDir === "tilted") {
    return 150
  } else if (oldDir === "down" && newDir === "right") {
    return -90
  } else if (oldDir === "down" && newDir === "left") {
    return 90
  } else if (oldDir === "left" && newDir === "up") {
    return 90
  } else if (oldDir === "left" && newDir === "tilted") {
    return 60
  } else if (oldDir === "left" && newDir === "right") {
    return 180
  } else if (oldDir === "left" && newDir === "down") {
    return -90
  }
}

const directionFromRotation = (rotation) => {

  const sin = Math.round(2 * Math.sin(toRadians(rotation)) * 100) / 100
  const cos = Math.round(Math.cos(toRadians(rotation)) * 100000) / 100000

  switch (sin) {
    case -2:
      return "left"
    case 0:
      return cos > 0 ? "up" : "down"
    case -1:
      return "tilted"
    case 2:
      return "right"
    default:
      return "down"
  }
}