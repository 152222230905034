import React, { useEffect, useRef, useContext } from "react"
import { CursorContext } from "../../../context/cursorContext"
import "./cursor.scss"

const Cursor = ({ location }) => {
  const { setCursor, cursorConfig } = useContext(CursorContext)

  const cursor = useRef()

  // bind our cursor to mouseMove event
  useEffect(() => {
    if (!window) return
    if (!document) return

    const onMouseMove = e => {
      cursor.current.style.transform = `translate(calc(${e.pageX}px), calc(${e.pageY}px))`
    }
    window.addEventListener("mousemove", onMouseMove)

    return () => {
      window.removeEventListener("mousemove", onMouseMove)
    }
  }, [])


  // set cursor on certain links
  useEffect(() => {
    if (!window) return
    if (!document) return

    const links = document.querySelectorAll("a:not(.design-trans, .design-nav-link, .page-menu-link), button")
    const pageAnchors = document.querySelectorAll("a.design-nav-link, a.page-menu-link")
    const greyCursor = () => {
      setCursor({
        ...cursorConfig,
        color: "var(--darkgrey)",
        shape: "default",
        rotation: -30,
        translation: "-6px, -6px",
        scale: "1",
      })
    }
    const blackCursor = () => {
      setCursor({
        ...cursorConfig,
        color: "var(--black)",
        shape: "default",
        rotation: -30,
        translation: "-6px, -6px",
        scale: "1",
      })
    }
    links.forEach(link => link.addEventListener("mouseenter", blackCursor))
    pageAnchors.forEach(anchor => anchor.addEventListener("mouseenter", blackCursor))
    links.forEach(link => link.addEventListener("mouseleave", greyCursor))

    return () => {
      links.forEach(link => link.removeEventListener("mouseenter", blackCursor))
      pageAnchors.forEach(anchor => anchor.removeEventListener("mouseenter", blackCursor))
      links.forEach(link => link.removeEventListener("mouseleave", greyCursor))
    }
  }, [location])

  return (
    <>
      <div
        ref={cursor}
        id="cursor"
        className="cursor"
        style={{
          color: cursorConfig?.color,
        }}
      >
        {cursorConfig && (
          <svg
            viewBox="0 0 64 64"
            fill={cursorConfig?.color}
            style={{
              // translate(-50%, -50%) to make sure svg is centered in cursor div
              transform: `translate(${cursorConfig?.translation}) rotate(${cursorConfig?.rotation}deg) scale(${cursorConfig?.scale})`,
              transition: "400ms ease transform",
            }}
          >
            <g className="cls-1">
              <g id="Ebene_2" data-name="Ebene 2">
                <g id="Layer_1" data-name="Layer 1">
                  <polygon
                    className="cls-2"
                    points="23.66 0 0 40.12 23.9 30.12 47.79 40.12 23.66 0"
                  />
                </g>
              </g>
            </g>
          </svg>
        )}
      </div>
    </>
  )
}

export default Cursor
