import { Link, navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import useIsMobile from "../../../hooks/useIsMobile"
import "./menu.scss"
import { useCurrentLang } from "../../../hooks/useCurrentLang"

const Menu = ({ location }) => {
  const [path, setPath] = useState("true")
  const [navVisibility, toggleNavVisibility] = useState(false)
  const isMobile = useIsMobile()
  const currentLang = useCurrentLang(location)
  useEffect(() => {
    setPath(location.pathname)
  }, [location])

  function toggleNav() {
    toggleNavVisibility(!navVisibility)
    document.querySelector(".page-menu").classList.toggle("is-active")
  }

  return (
    <>
      {/* <LanguageSwitch location={location} /> */}

      {/* <nav className={`menu ${isMobile ? "is-mobile" : ""}`}> */}
      <nav className={`menu`}>
        <ul>
          <li className={path.includes("ahoy") ? "ahoy active" : "ahoy"}>
            <Link to={currentLang === "en" ? "/ahoy" : `/${currentLang}/ahoy`} className="page-menu-link">
              ahoy
            </Link>
          </li>
          <li
            className={
              path.includes("design") || path.includes("top") || path === "/"
                ? "design active"
                : "design"
            }
          >
            <Link
              to={
                currentLang === "en" ? "/designs/" : `/${currentLang}/designs/`
              }
              className="page-menu-link"
            >
              {isMobile ? "work" : "design"}
            </Link>
          </li>

          <li className={path.includes("people") ? "people active" : "people"}>
            <Link
              to={currentLang === "en" ? "/people" : `/${currentLang}/people`}
              className="page-menu-link"
            >
              people
            </Link>
          </li>
          <li
            className={path.includes("contact") ? "contact active" : "contact"}
          >
            <Link
              to={currentLang === "en" ? "/contact" : `/${currentLang}/contact`}
              className="page-menu-link"
            >
              contact
            </Link>
          </li>

          <li
            className={
              path.includes("contact")
                ? "contact contact--mobile active"
                : "contact contact--mobile"
            }
          >
            <Link
              to={currentLang === "en" ? "/contact" : `/${currentLang}/contact`}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.06 26.86">
                <defs></defs>
                <g id="Ebene_2" data-name="Ebene 2">
                  <g id="Layer_1" data-name="Layer 1">
                    <path
                      style={{ fill: "#fff" }}
                      d="M11.71,20.59c-3.2,0-4.86-1.78-4.86-5.1C6.85,10.63,10.08,7,14.11,7a3.78,3.78,0,0,1,3.53,2l.8-1.47h2.67l-2.21,9a2.79,2.79,0,0,0-.09.62c0,.67.46,1,1.41,1A3.69,3.69,0,0,0,22.65,17a8.37,8.37,0,0,0,2.61-6.34c0-4.67-3.93-8.23-10-8.23a12.61,12.61,0,0,0-7.25,2,11.63,11.63,0,0,0-5.28,9.89c0,6.15,4.48,10.11,11.4,10.11a15.81,15.81,0,0,0,9.06-2.86l1.23,1.76a16.74,16.74,0,0,1-10.23,3.47A15.45,15.45,0,0,1,4.64,24,11.41,11.41,0,0,1,0,14.41,14,14,0,0,1,3.1,5.5,15.28,15.28,0,0,1,15.4,0,14.19,14.19,0,0,1,24,2.61a9.74,9.74,0,0,1,4,8.15,10.14,10.14,0,0,1-3.81,8.14,7.7,7.7,0,0,1-4.86,1.72c-2.12,0-3.19-.83-3.23-2.49A4.93,4.93,0,0,1,11.71,20.59ZM14.29,9.22c-2.24,0-4.24,2.8-4.24,6.33,0,1.84.83,2.83,2.37,2.83A4.08,4.08,0,0,0,16.1,15l.8-3.26A2.53,2.53,0,0,0,14.29,9.22Z"
                    />
                  </g>
                </g>
              </svg>
            </Link>
          </li>
        </ul>
      </nav>
    </>
  )
}

export default Menu
