/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const  React = require('react');
// const { GeneralContextProvider } = require("./src/context")
const { CursorProvider } = require("./src/context/cursorContext")

// You can delete this file if you're not using it

require("./src/assets/scss/index.scss")

exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {

  if (location.pathname === '/designs/' || location.pathname === '/') {
    document
      .querySelector("#container")?.scroll(0, window.sessionStorage.designPostion)
  }
}

exports.wrapRootElement = ({ element }) => {
  return (
      <CursorProvider>
        {element}
      </CursorProvider>
  )
}

exports.onPreRouteUpdate = ({ location, prevLocation }) => {
  if (location?.pathname === '/designs/' || location?.pathname === '/') {
    window.sessionStorage.setItem('designPostion', document.querySelector("#container")?.scrollTop || 0)
  }
  document?.querySelector("#container")?.scroll(0, 0)
}
