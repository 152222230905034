import { useEffect, useState } from "react"

export const useCurrentLang = location => {
  const [currentLang, setCurrentLang] = useState("en")

  useEffect(() => {
    const lang = location.pathname.split("/")[1]
    setCurrentLang(lang === "de" ? "de" : "en")
  }, [location])

  return currentLang
}
