import { useEffect, useState } from "react"

export default function useIsMobile() {
  const isClient = typeof window === "object"

  const breakpoint = 980

  function isMobile() {
    if (isClient) {
      return window.innerWidth <= breakpoint
    } else {
      return false
    }
  }

  const [windowSize, setIsMobile] = useState(isMobile)

  useEffect(() => {
    if (!isClient) {
      return false
    }

    const mql = window.matchMedia(`(max-width: ${breakpoint}px)`)

    function handleMatchMedia(e) {
      setIsMobile(isMobile())
    }

    mql.addListener(handleMatchMedia)

    return () => mql.removeListener(handleMatchMedia)
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return windowSize
}
