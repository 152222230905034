import React, { useContext } from "react"
import { CursorContext } from '../../context/cursorContext'
import { Link } from "gatsby"

const DesignNav = ({ location, count }) => {
  const currentPage = (location?.pathname || "").includes("top")
    ? "top"
    : "design"

  const { cursorConfig, setCursor } = useContext(CursorContext);

  return (
    <nav id="design-nav">
      <ul>
        <li>
          <Link
            to="/top"
            className={`design-nav-link ${
              currentPage === "top" ? "active" : ""
            }`}
          >
            case studies
          </Link>
        </li>
        <li>
          <Link
            to="/designs"
            className={`design-nav-link ${
              currentPage === "design" ? "active" : ""
            }`}
          >
            everything
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default DesignNav
